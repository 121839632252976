class UXManager {
    constructor() {
        this.addListeners();
    }

    isEditing() {
        return !!$('#ccm-menu-click-proxy').length;
    }

    addListeners() {
        $(window).on('scroll resize', this.onScroll);
        $(window).trigger('scroll');
        $(document).ready((this.onReady).bind(this));
    
        $(".tile.banner.position_.event")
        .each(function (index) {
          $(this).attr("data-id", index);
        })
        .on("click", function () {
          let container = $(".zoomedimage");
          let imageSrc = $(this).find(".image").attr("data-bg");
          if ($(".zoomedimage").length == 0) {
            $("body")
              .append('<div class="zoomedimage tile banner position_ event"></div>')
              .css("overflowY", "hidden");
            container = $(".zoomedimage");
          }
          container
            .css("backgroundImage", "url(" + imageSrc + ")")
            .attr("data-rel", $(this).attr("data-id"))
            .hide()
            .fadeIn(500);
        });
    
        $(document).on("click", ".zoomedimage", function () {
          $("body").css("overflowY", "scroll");
          $(this).fadeOut(500);
        });
    }

    onScroll() {
        const animation_elements = $('.animation-element');

        const window_height = $(window).height();
        const window_top_position = $(window).scrollTop();
        const window_bottom_position = (window_top_position + window_height);

        if (window_top_position == 0) {
            $('body').removeClass('scrolled');
        } else {
            $('body').addClass('scrolled');
        }

        $.each(animation_elements, function () {
            const element_height = $(this).outerHeight();
            const element_top_position = $(this).offset().top;
            const element_bottom_position = (element_top_position + element_height);

            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                $(this).addClass('in-view');
            } else {
                $(this).removeClass('in-view');
            }
        });

        // fix to show shearchsystem on menu
        if (document.getElementsByClassName('searchsystem').length >= 1) {
            var firstSearchBar = document.getElementsByClassName('searchsystem')[1];

            if (typeof firstSearchBar == typeof undefined) return;

            if (firstSearchBar.offsetTop < $(window).scrollTop()) {
                if ($('.navsearchcontainer').hasClass('d-none')) {
                    $('.navsearchcontainer').removeClass('d-none');
                }
                if (!$('.navlinkscontainer').hasClass('d-none')) {
                    $('.navlinkscontainer').addClass('d-none');
                }
            } else {
                if (!$('.navsearchcontainer').hasClass('d-none')) {
                    $('.navsearchcontainer').addClass('d-none');
                }
                if ($('.navlinkscontainer').hasClass('d-none')) {
                    $('.navlinkscontainer').removeClass('d-none');
                }
            }
        }


    }

    onReady() {
        this.convertImgToSvg();
        this.activateTabsets();
        this.animateKeyNumbers();
        this.activateCustomSelect();
        this.activateCalendarTrigger();
        this.activateDrawer();
        this.activateLazyContentLists();
        this.activateZoomables();
        this.activateLoadMore();
        this.activateDestinationLinks();
        this.activateHamburger();
        this.fixHPUX();
        this.fixClubUX();
        this.fixAggregatorUX();
        this.fixCartoucheUX();
        this.activateContactTabs();
        this.monitorTouchpontSubmit();
    }

    convertImgToSvg() {
        $('img.svg').each(function () {
            const img = $(this);
            const imgID = img.attr('id');
            const imgClass = img.attr('class');
            const imgURL = img.attr('src');
            const altText = img.attr('alt');
            $.get(imgURL, function (data) {
                let svg = $(data).find('svg');
                if (typeof imgID !== 'undefined') {
                    svg = svg.attr('id', imgID);
                    svg = svg.attr('alt', altText);
                }
                if (typeof imgClass !== 'undefined') {
                    svg = svg.attr('class', imgClass + ' replaced-svg');
                }
                svg = svg.removeAttr('xmlns:a');
                if (!svg.attr('viewBox') && svg.attr('height') && svg.attr('width')) {
                    svg.attr('viewBox', '0 0 ' + svg.attr('height') + ' ' + svg.attr('width'))
                }
                img.replaceWith(svg);
            }, 'xml');
        });
    }

    activateTabsets() {
        let foundts = [];
        $('[data-tabset]').each(function () {
            const tsn = $(this).attr('data-tabset');
            const trn = $(this).attr('data-tabrel');
            const tab = $('[data-tab="' + trn + '"]');

            $(tab).on('click', function (e) { ux.clickTabsetTab(this); });

            if (!foundts.includes(tsn)) {
                foundts.push(tsn);
                $(this).show();

                tab.addClass('active');
            } else {
                if (!ux.isEditing()) {
                    $(this).hide();
                }
                tab.removeClass('active');
            }
        })
    }

    clickTabsetTab(el) {
        const trn = $(el).attr('data-tab');
        const tabc = $('[data-tabrel="' + trn + '"]');
        const tsn = tabc.attr('data-tabset');

        $('[data-tabset="' + tsn + '"]').each(function () {
            const trn = $(this).attr('data-tabrel');
            const tab = $('[data-tab="' + trn + '"]');

            tab.removeClass('active');
            $(this).hide();
        })

        $(el).addClass('active');
        tabc.show();
    }

    animateKeyNumbers() {
        $('.key-number').each(function () {
            var $this = $(this);

            const countTo = $(this).children('span').text();

            if  ( $this.hasClass('.key-number-animated')) {
                $({ countNum: 1 }).animate({
                    countNum: countTo
                }, {
                    duration: 2000,
                    easing: 'swing',
                    step: function () {
                        const l = $('html').attr('lang');
                        const s = $this.children('span');
                        let t = Math.floor(this.countNum).toLocaleString(l);
                        if (s.attr('data-append')) t += s.attr('data-append');
                        s.text(t);
                    },
                    complete: function () {
                        const l = $('html').attr('lang');
                        const s = $this.children('span');
                        let t = this.countNum.toLocaleString(l);
                        if (s.attr('data-append')) t += s.attr('data-append');
                        s.text(t);
                    }
                });
            } else {
                const l = $('html').attr('lang');
                const s = $this.children('span');
                let t = Math.floor(countTo).toLocaleString(l);
                if (s.attr('data-append')) t += s.attr('data-append');
                s.text(t);
            }
        });
    }

    activateCustomSelect() {
        $(document).on('click focus', '.component.form-element.select:not(.status-disabled)', function (e) {
            if ($(e.target).hasClass('search-element')) return;
            if ($(e.target).parent().hasClass('search-element')) return;

            if (window.ss.pro) {
                if ($(this).children('.optionpositioner').is(':visible')) {
                    //console.log('NON PREPARO',  $(this).hasClass('activity') ? 'activity' : 'address');
                    return;
                }
                //console.log('PREPARO IL TIMEOUT DI APERTURA',  $(this).hasClass('activity') ? 'activity' : 'address');
                setTimeout($.proxy(function () {

                    const op = $(this).children('.optionpositioner');
                    const sf = $(this).children('[data-role="search"]');

                    if (op.is(':visible')) {
                        //console.log('ESCO DAL TIMEOUT DI APERTURA PERCHÈ VISIBILE',  $(this).hasClass('activity') ? 'activity' : 'address');
                        return;
                    }

                    //console.log('ENTRO NEL TIMEOUT DI APERTURA', $(this).hasClass('activity') ? 'activity' : 'address');
                    if (sf.hasClass('focus')) {
                        //console.log('||||||||||||||||||||');
                        //console.log('procedo a aprire', $(this).hasClass('activity') ? 'activity' : 'address');
                        op.show();
                        op.closest('.component.searchsystem').addClass('open');


                        if ($(window).width() <= 576) {
                            //console.log('------------------------------');
                            //console.log('procedo a selezionare tutto il contenuto del campo', sf);

                            //console.log('||||||||||||||||||||');
                            //console.log('procedo a bloccare lo scroll su body');
                            $('body').css('overflow-y', 'hidden');
                            $(sf).select();
                        }

                    } 
                }, this), 200)

            } else if (e.type == 'click') {
                const op = $(this).children('.optionpositioner');
                if (op.is(':visible')) {
                    //console.log('|||||||||||||||||||| click focus');
                    //console.log('procedo a chiudere', op);

                    op.hide();
                } else {
                    //console.log('|||||||||||||||||||| click focus');
                    //console.log('procedo a aprire', op);

                    op.show();
                    op.find('[type="search"]').focus();
                }
            }
        });

        $(document).on('blur', '.component.form-element.select:not(.status-disabled)', function (e) {
            if (window.ss.pro) {
                window.ss.handleOptionAutoSelect($(this).children('.optionpositioner'));

                /*
                setTimeout($.proxy(function () {
                    $(this).children('[data-role="search"]').removeClass('focus');
                    const op = $(this).children('.optionpositioner');
                    op.hide();
                    
                    console.log('|||||||||||||||||||| blur');
                    console.log('procedo a chiudere', op.parent().hasClass('activity') ? 'activity' : 'address');
                }, this), 200)
                */
            }
        });

        $(document).on('click', '.component.form-element.select .options li', function (el) {
            if ($(this).hasClass('search-unclickable-element')) return;

            $(this).siblings().removeClass('selected');
            $(this).addClass('selected');

            const op = $(this).closest('.component.form-element');
            op.children('input[type="text"]').val($(this).text());
            op.children('input[type="hidden"]').val($(this).attr('data-value'));
        });

        $(document).on('click', function (e) {
            //console.log('#################');
            //console.log('window.ss.pro', window.ss.pro);
            //console.log('$(window).width()', $(window).width());
            //console.log('target dr', $(e.target).attr('data-role'));
            //console.log('target parent dr', $(e.target).parent().attr('data-role'));
            //console.log('condition', ($(e.target).attr('data-role') == 'search' || $(e.target).parent().attr('data-role') == 'search'));
            

            if (window.ss.pro && $(window).width() <= 576 && !($(e.target).attr('data-role') == 'search' || $(e.target).parent().attr('data-role') == 'search')) {
                //console.log('NON chiudo perché  NON ho un focus su un altro search');
                return;
            } else if (typeof window.ss.pro == typeof undefined) {
                if ($(e.target).hasClass('search-element')) return;
                if ($(e.target).parent().hasClass('search-element')) return;
            }

            const cfe = $(e.target).closest('.component.form-element');
            const op = cfe.find($('.optionpositioner'));

            $('.optionpositioner').each(function () {
                if (!$(this).is(op) && $(this).is(':visible')) {
                    //console.log('|||||||||||||||||||| document click');
                    //console.log('procedo a chiudere', this);

                    $(this).hide();
                } 
            });
        });
    }

    activateLazyContentLists() {
        $('.async_content_list:not(".deferred")').each(function () {
            //console.log('00 |||||||||||||||||||| activateLazyContentLists', this);
            window.ux.activateLazyContentList(this); 
        });
    }

    activateLazyContentList(el, insertiontarget, insertionmode, contentlevel) {
        //console.log((new Error()).stack);
        //console.log('01 |||||||||||||||||||| activateLazyContentList', $(el));
        
        //return false;
        const bid = $(el).attr('data-bid');
        let filters = {};
        try {
            filters[bid] = JSON.parse($(el).attr('data-filters'));
        } catch (e) {
            console.error('Errore nel parsing del json filters', $(el).attr('data-filters'));
            console.log(el);
            return false;
        }

        filters = this.scanForParentselector(filters, bid);
        const id = $(el).attr('data-id');
        const segment = $(el).attr('data-segment');
        const template = $(el).attr('data-template');
        let settings = {};

        try {
            settings = JSON.parse($(el).attr('data-settings'));
        } catch (e) {
            console.error('Errore nel parsing del json settings', $(el).attr('data-settings'));
            console.log(el);
            return false;
        }

        if (id == '------') return false;
        if (!insertiontarget) insertiontarget = el;
        if (!insertionmode) insertionmode = 'replace';

        this.getLazyContentList(el, bid, filters, id, segment, template, settings, insertiontarget, insertionmode, contentlevel);
    }

    scanForParentselector(filters, bid) {

        if (filters[bid].hasOwnProperty('parentselector')) {
            const parentid = $(filters[bid].parentselector).attr('data-parentid');
            if (typeof parentid !== 'undefined') {
                filters[bid].parentid = parentid;
            }
        }

        return filters;
    }

    getLazyContentList(el, bid, filters, id, segment, template, settings, insertiontarget, insertionmode, contentlevel) {
        //return false;

        //console.log('02 |||||||||||||||||||| getLazyContentList', $(el));

        const data = { 'filters': filters, 'id': id, 'bid': bid, 'segment': segment, 'template': template, 'settings': settings };

        const options = {
            type: "POST",
            dataType: "html",
            url: '/content/list',
            context: el,
            data: JSON.stringify(data),
            success: function (j) {
                //console.log('03 |||||||||||||||||||| getLazyContentList RESPONSE', $(el));
                if ($(el).hasClass('async_content_list')) {
                    window.ux.renderContentList(el, j, bid, filters, settings, insertiontarget, insertionmode, contentlevel);
                    window.ux.fixAggregatorUX();
                } else if ($(el).hasClass('drawer')) {
                    window.ux.renderDrawer(el, j, bid, filters, settings);
                }
            },
            error: function (j) {
            }
        };
        $.ajax(options);
    }

    skeletonContentList(el) {
        $(el).find('.tile:not(.skeleton)').addClass('skeleton');
    }

    renderContentList(el, j, bid, filters, settings, insertiontarget, insertionmode, contentlevel) {
        if (contentlevel == 'children') {
            let cj = $.parseHTML(j);
            j = $(cj).children();
        }

        this.insertInDom(insertiontarget, j, insertionmode);

        this.renderCount(el);
        let cardsnum = $(el).find('[data-card]');

        if (cardsnum.length == 0 && settings.emptystate == 'hide' && $('#ccm-menu-click-proxy').length == 0) {
            $(el).hide();
        } else if (cardsnum.length == 0 && settings.emptystate == 'hide' && $('#ccm-menu-click-proxy').length > 0) {
            $(el).css('opacity', 0.5);
            const esm = '<div class="component emptystate">Content List Empty, will disappear in public mode</div>';

            this.insertInDom(el, esm, (settings.emptystatepos) ? settings.emptystatepos : 'append');

        } else if (cardsnum.length == 0 && settings.emptystate == 'message') {
            this.insertInDom(el, '<div class="component emptystate">' + settings.emptystatemessage + '</div>', (settings.emptystatepos) ? settings.emptystatepos : 'append');
        } else {
            window.ll.update();
        }

        // cerco le contentlist annidate e le attivo
        let near = filters[bid].near;
        $(el).find('.async_content_list:not(.async_content_list_done)').each(function () {
            let f;
            try {
                f = JSON.parse($(this).attr('data-filters'));
                if (f.near) {
                    f.near = near;
                    $(this).attr('data-filters', JSON.stringify(f));
                }

            } catch (e) {
                console.error('Error parsing JSON in annidated contentlist', $(this).attr('data-filters'));
                return;
            }

            //return false;
            // lancio l'attivazione corrente

            //console.log('00 |||||||||||||||||||| renderContentList annidated', $(this));
            window.ux.activateLazyContentList(this);

            // disattivo la contentlist per evitare future attivazioni
            $(this).addClass('async_content_list_done');
        })
    }

    insertInDom(target, content, strategy) {
        if (strategy == 'prepend') {
            $(target).prepend(content);
        } else if (strategy == 'append') {
            $(target).append(content);
        } else if (strategy == 'before') {
            $(target).before(content);
        } else if (strategy == 'after') {
            $(target).after(content);
        } else if (strategy == 'replaceWith') {
            $(target).replaceWith(content);
        } else {
            $(target).html(content);
        }
    }

    renderCount(el) {
        let countel;
        if ($(el).attr('data-countable') == true) {
            countel = el;
        } else {
            countel = $(el).closest('[data-countable="true"]');
        }
        if (countel.length > 0) {
            const shown = $(countel).find('.tile').length;
            const total = $(countel).attr('data-total');
            const delta = total - shown;
            const ds = $(countel).find('.drawerset').first();
            const lt = $(countel).find('.tile').last();

            if (delta > 0 && ds.length > 0 && lt.length > 0) {
                const class_ = lt.attr('class');
                ds.before('<div class="' + class_ + ' counter"><a href="javascript:return void(0)" onclick="window.ux.proxyDrawerTrigger(this)"><div class="tilehead">+' + delta + '</div><div class="tiletitle"><h3 class="title">' + $(countel).attr('data-countlabel') + '</h3></div></a></div>')
            }
        };
    }

    proxyDrawerTrigger(el) {
        $(el).closest('.contentlist').find('.drawertrigger').trigger('click');
    }

    activateDrawer() {
        $(document).on('click', '.drawertrigger', function () {
            const el = $(this).siblings('.drawer').get(0);

            if (!$(el).is(':empty')) {
                window.ux.openDrawer(el, false);
                return false;
            }

            const wr = $(this).closest('.async_content_list')
            const bid = $(wr).attr('data-bid');
            let filters = {};

            filters[bid] = JSON.parse($(wr).attr('data-filters'));
            filters[bid] = window.ux.shapeFiltersDrawer(filters[bid]);
            filters = window.ux.scanForParentselector(filters, bid);

            const id = $(wr).attr('data-id');
            const segment = $(wr).attr('data-segment');
            const template = $(wr).attr('data-template');
            const settings = JSON.parse($(wr).attr('data-settings'));

            window.ux.getLazyContentList(el, bid, filters, id, segment, template, settings);
        });

        $(document).on('click', '.drawerfader, .drawer .closer', function () {
            window.ux.closeDrawer($(this).closest('.drawerset'));
        });
    }

    shapeFiltersDrawer(filters) {

        filters.listlimit = "100";
        if (JSON.stringify(filters['type']) == JSON.stringify(['event', 'promo'])) {
            delete filters['custom'];
        }
        return filters;
    }

    renderDrawer(el, j, bid, filters) {
        //return false;
        $(el).html(j);

        //hack to avoid recursion
        $(el).find('.drawerset').remove();

        //hack title system
        $(el).find('.contentlist_title > h3').remove();
        $(el).find('.contentlist_title > p').remove();
        $(el).find('.contentlist_title').append('<div class="closer"></div>');

        //hack swiper
        if ($(el).find('.swiper').length > 0) {
            $(el).find('.swiper-controls').remove();
            $(el).find('.swiper').removeClass('swiper').addClass('mask');
            $(el).find('.swiper-wrapper').removeClass('swiper-wrapper').addClass('runner');
        } else {
            $(el).find('.contentlist_title').after('<div class="mask"><div class="runner"></div></div>');
            $(el).find('.tile').appendTo($(el).find('.runner'));
        }

        //hack per prodotti
        $(el).find('.tile.product.standard').removeClass('standard').addClass('medium');

        this.openDrawer(el, true);

        let cardsnum = $(el).find('[data-card]');

        if (cardsnum.length == 0 && settings.emptystate == 'hide' && $('#ccm-menu-click-proxy').length == 0) {
            $(el).hide();
        } else if (cardsnum.length == 0 && settings.emptystate == 'hide' && $('#ccm-menu-click-proxy').length > 0) {
            $(el).css('opacity', 0.5);
            $(el).append('<div class="component emptystate">Content List Empty, will disappear in public mode</div>');
        } else if (cardsnum.length == 0 && settings.emptystate == 'message') {
            $(el).append('<div class="component emptystate">' + settings.emptystatemessage + '</div>');
        } else {
            window.ll.update();
        }
    }

    openDrawer(el, wait) {
        if (wait) {
            setTimeout(function () {
                $(el).parent('.drawerset').addClass('open');
            }, 200);
        } else {
            $(el).parent('.drawerset').addClass('open');
        }

        $('body').addClass('modalactive');
    }

    closeDrawer(el) {
        $(el).removeClass('open');
        $('body').removeClass('modalactive');
    }

    activateCalendarTrigger() {
        $('.component.calendar').children('.trigger').on('click', function () {
            $(this).parent().toggleClass('open');
        });
    }

    activateZoomables() {
        $(document).on('click', '.zoomable', function (e) {
            if ($(e.target).attr('role') == 'button') return;
            $(this).toggleClass('zoomed');

            if ($(this).hasClass('zoomed')) {
                $(this).find('img[data-original-width]').each(function () {
                    setTimeout($.proxy(function () {
                        const nh = $(window).height() * 0.8;//$(this).height();
                        const ow = $(this).attr('data-original-width');
                        const oh = $(this).attr('data-original-height');

                        $(this).width(nh * ow / oh);
                        $(this).height(nh);
                    }, this), 500);
                });
            } else {
                $(this).find('img[data-original-width]').each(function () {
                    $(this).width('');
                    $(this).height('');
                });
            }
        });
    }

    activateLoadMore() {
        $(document).on('click', '.loadmore:not(.status-disabled)', function () {
            if ($(this).attr('data-role') == 'proxy') {
                const targetid = $(this).attr('data-target');
                $('.loadmore[data-targetid="' + targetid + '"]').trigger('click');
                $(this).removeClass('loadmore').addClass('status-loading');
            } else if ($(this).attr('data-mode') == 'paginated') {
                window.ux.loadmorePaginated(this)
            } else {
                window.ux.loadmoreContinuous(this)
            }
        });
    }

    loadmoreContinuous(el) {
        // cambio lo status del bottone
        $(el).removeClass('loadmore').addClass('status-loading');

        // recupero l'elemento con i dati
        let cl = $(el).closest('[data-filters]');

        // incremento il from in filters
        let filters = JSON.parse(cl.attr('data-filters'));
        filters.listfrom = parseInt(filters.listfrom) + parseInt(filters.listlimit);
        cl.attr('data-filters', JSON.stringify(filters));

        // incremento il from in pagination
        let pagination = JSON.parse(cl.attr('data-pagination'));
        pagination.from = parseInt(pagination.from) + parseInt(filters.listlimit);
        cl.attr('data-pagination', JSON.stringify(pagination));

        // carico la prossima pagina
        console.log('00 |||||||||||||||||||| loadmoreContinuous ', $(cl));
        window.ux.activateLazyContentList(cl, el, 'replaceWith', 'children');
    }

    loadmorePaginated(el) {
        // cambio lo status del bottone
        $(el).removeClass('loadmore').addClass('status-loading');

        // recupero l'elemento con i dati
        let cl = $(el).closest('[data-filters]');

        // modifico il from in filters
        let filters = JSON.parse(cl.attr('data-filters'));
        if ($(el).attr('data-action') == 'prev') {
            filters.listfrom = parseInt(filters.listfrom) - parseInt(filters.listlimit);
        } else {
            filters.listfrom = parseInt(filters.listfrom) + parseInt(filters.listlimit);
        }
        cl.attr('data-filters', JSON.stringify(filters));

        // incremento il from in pagination
        let pagination = JSON.parse($(el).closest('.pagination').attr('data-pagination'));
        if ($(el).attr('data-action') == 'prev') {
            pagination.from = parseInt(pagination.from) - parseInt(filters.listlimit);
        } else {
            pagination.from = parseInt(pagination.from) + parseInt(filters.listlimit);
        }
        $(el).closest('.pagination').attr('data-pagination', JSON.stringify(pagination));

        // mando in skeleton e svuoto la mappa
        $(el).closest('.contentlist').children('.tile').addClass('skeleton').off('mouseenter').off('mouseleave');

        if (typeof window.gmac != typeof undefined) {
            window.gmac.emptyMarkers();
            
            if ($(window).width() > 991 ) {
                $("html, body").animate({ scrollTop: 0 }, 300);
            }
        }

        // carico la prossima pagina
        setTimeout(function () {
            //console.log('00 |||||||||||||||||||| loadmorePaginated ', $(cl));
            window.ux.activateLazyContentList(cl, $(el).closest('.contentlist'), null, 'children');
        }, 100);
    }

    activateHamburger() {
        $('.hamburger').on('click', function () {
            $(this).toggleClass('open');
            $('nav').find('.menu').toggleClass('open');
        })
    }

    fixHPUX() {
        if ($('body').hasClass('homepage')) {

        }
    }

    fixAggregatorUX() {
        if ($('body').hasClass('aggregator')) {
            if ($(window).width() < 576 && typeof window.gmac != typeof undefined) {
                $("html, body").animate({ scrollTop: 80 }, 300);
                const h = $(window).height() - 57;
                $('section.list').height(h);
                $('section.list').find('aside').height(h);
            }

            $('.fewresults[data-action]').on('click', function () {
                if ($(this).attr('data-action') == 'remove-filters') {
                    let url = window.location.href;
                    window.location.href = url.substring(0, url.lastIndexOf('/'));
                }
            });
        }
    }

    fixCartoucheUX() {
        if (
            $('body').hasClass('store') &&
            $(window).width() < 768
        ) {
            const l = $('.activityswitcher').find('.component.button').first().attr('href');
            $('.cartouche').find('.component.button').attr('href', l);
        } else if (
            ($('body').hasClass('event') ||
                $('body').hasClass('promo') ||
                $('body').hasClass('product')) &&
            $(window).width() < 768
        ) {
            const l = $('.component.ctainline').attr('href');
            $('.cartouche').find('.component.button').attr('href', l);
        } else {
            //console.log('no need to fix')
        }
    }

    activateContactTabs() {
        if ($('.contact-tabs').length) {
            $('.single-tab').on('click', function (e) {
                var tabToShow = $(this).attr('data-target');
                if (tabToShow == 'club') {
                    $(this).parent().parent().parent().parent().parent().parent().css('background-color', '#F2F7FE');
                } else {
                    $(this).parent().parent().parent().parent().parent().parent().css('background-color', '#FFF4F4');
                }
                if (!$(this).hasClass('active')) {
                    $('.single-tab').removeClass('active');
                    $(this).addClass('active');

                    $('.single-content').removeClass('active');
                    $('.single-content.' + tabToShow).addClass('active');
                }
            });
        }
    }

    monitorTouchpontSubmit() {
        $('form.touchpoint').on('submit', function() {
            $(this).find('input[type="submit"]').prop('disabled', true);
        });
    }

    fixClubUX() {
        if ($('body').hasClass('store')) {
            setTimeout(function () {
                if (!$('.body > .subscriptions > .async_content_list').is(':visible')) {
                    $('li[data-rel="subscriptions"]').hide();
                }
                if (!$('.body > .activities > .async_content_list').is(':visible')) {
                    $('li[data-rel="activities"]').hide();
                }
                if ($('.component.reviews').length == 0) {
                    $('li[data-rel="reviews"]').hide();
                }
            }, 2000);
        }
    }

    activateDestinationLinks() {
        $(document).on('click', '[data-destination]', function (e) {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = $(this).attr('data-destination');
        });
    }
}

module.exports = UXManager;