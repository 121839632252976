import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';

window.getClientRects = function () {
    return { top: 0, left: 0 };
}

window.$ = window.jQuery = $;
datepickerFactory($);

import Swiper from 'swiper/bundle';
import UXManager from './libs/ux.js';
import LazyLoadManager from './libs/lazyload.js';
import CountDownManager from './libs/countdown.js';
import SwiperManager from './libs/swiper.js';
import QRCodeManager from './libs/qrcode.js';
import VideoControlsManager from './libs/videocontrols.js';
import InvieportManager from './libs/inviewport.js';
import GoogleMapsManager from './libs/googlemaps.js';
import GoogleMapsAsideManager from './libs/googlemapsaside.js';
import SearchSystemManager from './libs/searchsystem.js';
import GeolocationManager from './libs/geolocator.js';
import FAQManager from './libs/faq.js';
import ModalizerManager from './libs/modalizer.js';

$(document).ready(function () {
    const ux = new UXManager();
    window.ux = ux;

    $('.countdown').each(function () {
        const cd = new CountDownManager($(this));
    });
    
    $('.component.qrcode').each(function () {
        const qr = new QRCodeManager($(this));
    });

    // prima faccio partire gli async che conterranno eventualmente anche dei '.component .swiper'
    $('.async_content_list').each(function () {
        //console.log('MAIN - faccio partire asincrono su', $(this));
        const sma = new SwiperManager($(this), true);
    });

    // poi faccio partire i '.component .swiper' non asincroni
    $('.component .swiper').each(function () {
        //console.log('MAIN - guardo il parent di component', $(this).closest('.component').parent());
        if ($(this).closest('.component').parent().hasClass('async_content_list')) {
            //console.log('MAIN - Blocco perché dentro a un async_content_list', $(this));
        } else {
            //console.log('MAIN - faccio partire sincrono su', $(this));
            const sm = new SwiperManager($(this), false);
        }
    });

    $('video.hascustomcontrols').each(function () {
        const vc = new VideoControlsManager($(this));
    });

    $('[data-role="faq"]').each(function () {
        const fm = new FAQManager($(this));
    });

    $('.modalizer').each(function () {
        const modm = new ModalizerManager($(this));
    });

    $('#map_opener').on('click', () => {
        $('#map_opener').addClass('unshow');
        $('#map_blurr').addClass('unshow');
        $('.component.map.googlemaps').removeClass('unshow');
        $('.component.map.googlemaps').addClass('showed');
        let script = document.createElement('script');
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBAZEkqnfy411LAXw9gHpuq5xDA0KcSpIc&libraries=places&callback=initMap";
        script.async = true;
        window.initMap = function() {
            $('.component.map.googlemaps').each(function () {
                const gmc = new GoogleMapsManager($(this));
            })
        };
        document.body.appendChild(script);
    });


    if ($('.component.map.googlemapsaside').length > 0) {
        window.gmac = new GoogleMapsAsideManager($('.component.map.googlemapsaside'));
    }

    window.ll = new LazyLoadManager();
    window.ivm = new InvieportManager();

    setTimeout(function () {
        window.ss = new SearchSystemManager();
    }, 500);

    setTimeout(function() {
        window.glm = new GeolocationManager();
    }, 1000)
});
